<template>
  <v-select
    :disabled="disabled"
    :options="options"
    :filterable="false"
    :label="label"
    :reduce="reduce"
    :placeholder="placeholder"
    v-model="selectedValue"
    @open="onOpen"
    @close="onClose"
    @search="onSearch"
    :multiple="isMultiple"
    @option:selected="optionSelected"
    ref="dropdownRef"
  >
    <template v-slot:option="option">
      {{ formatLabel(option) }}
    </template>
    <template #list-footer>
      <li v-show="hasNextPage" ref="load" class="loader">
        Loading more options...
      </li>
    </template>
  </v-select>
</template>

<script setup>
import {computed, inject, nextTick, ref, watch} from "vue";
import {useRoute} from "vue-router";

  const paginated = ref([])
  const $route = useRoute()
  const emit = defineEmits([
    'update:modelValue',
    'onClear'
  ]);
  const props = defineProps({
    additionalOption: {
      type: Array,
      default: []
    },
    modelValue: {
      required: true
    },
    limit: {
      type: Number,
      default: 10,
      required: false
    },
    label: {
      type: String,
      default: "name"
    },
    placeholder: {
      type: String,
      default: "Please search or select"
    },
    searchKey: {
      type: String,
      default: "q"
    },
    urlParam: {
        type: Number,
        default: null
    },
    additionalQuery: {
      type: Object,
      default: {}
    },
    formatLabel: {
      type: Function,
      default: (option) => option.name
    },
    optionSelected: {
      type: Function,
      default: () => {}
    },
    reduce: {
      default: name => name.id
    },
    apiService: {
      required: true
    },
    isMultiple: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    }
  })
  const observer = new IntersectionObserver(entries => infiniteScroll(entries))
  const page = ref(0)
  const load = ref(null)
  const dropdownRef = ref(null)
  const query = ref('')
  const showError = inject('showError');
  const hasNextPage = ref(true);
  const parentUl = ref(null);
  let position = 0;

  const getQuery = (firstPage = false) => {
    const additionalQuery = props.additionalQuery;
    let companyQuery = `?company_id=${$route.params.companyId}&page=${!firstPage ? page.value: 1}&per_page=${props.limit}`;

    if (additionalQuery && Object.keys(additionalQuery).length > 0) {
      let params = new URLSearchParams(props.additionalQuery);
      companyQuery += `&${params.toString()}`
    }

    if (query.value && query.value !== '') {
      companyQuery += `&${props.searchKey}=${query.value}`
    }

    return companyQuery;
  }

  const selectedValue = computed({
    get: () => props.modelValue,
    set: (value) => emit('update:modelValue', value)
  })

  const options = computed(() => {
      if ( props.additionalOption ) {
          return [ ...props.additionalOption, ...paginated.value]
      }
      return paginated ? paginated.value : []
  })

  //watcher
  watch(page, (newValue) => {
    if (newValue) {
      handleAPICall(getQuery(), false)
    }
  })

  watch(query, () => {
    handleAPICall(getQuery(true))
  })

  watch(selectedValue, (newValue, oldValue) => {
    if (oldValue !== null && newValue === null) {
      emit('onClear')
      query.value = '';
    }
  })

  const onSearch = _.debounce((search) => {
    position = 0;
    query.value = search;
  }, 350);

  const clear = () => {
    paginated.value = [];
    page.value = 0;
    hasNextPage.value = true;
  };

  const onOpen = async () => {
    if (query.value !== '' && query.value.length && !selectedValue.value) {
      query.value = '';
    }

    if (hasNextPage.value) {
      await nextTick()
      observer.observe(load.value)
    }
  }
  const onClose = () => {
    observer.disconnect()
  }

  const callApi = (companyQuery) => {
    if (! props.urlParam) {
      return props.apiService(companyQuery);
    }

    return props.apiService(props.urlParam, companyQuery);
  }

  const handleAPICall = (companyQuery, refreshOption = true) => {
    callApi(companyQuery).then(res => {
      if (! res.data) {
        return;
      }

      page.value = res.data.current_page ?? 1;
      hasNextPage.value = res.data.current_page !== res.data.last_page;

      if (refreshOption) {
        paginated.value = res.data.data
        return;
      }

      paginated.value = [
        ...paginated.value,
        ...res.data.data
      ];
    }).finally(() => {
      parentUl.value.scrollTop = position;
    })
  }

  const infiniteScroll = async ([{ isIntersecting, target }]) => {
    if (!isIntersecting) {
      return;
    }

    page.value++;
    await nextTick();
    if(target) {
      parentUl.value = target.offsetParent;
      position = target.offsetParent.scrollTop;
    }
  }

  defineExpose({
      dropdownRef,
      clear
  })
</script>
